<template>
  <div class="home">
    <div class="searchNav">
      <el-button
        class="button"
        icon="el-icon-download"
        type="primary"
        style="margin-left: 0"
        :loading="addloading"
        @click="handleDownload(selectRows)"
        >批量下载</el-button
      >
      <div class="searchParams">
        <div>
          <radioGroup
            :hidden="true"
            @radioChange="radioChange"
            @complete="radioComplete"
          />
        </div>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%"
      :max-height="height"
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" :selectable="isRender">
      </el-table-column>
      <el-table-column prop="BillId" label="单据编号" width="200">
      </el-table-column>
      <el-table-column prop="cCusName" label="客户" width="200">
      </el-table-column>
      <el-table-column prop="TimeStamp" label="日期" width="200">
        <template slot-scope="scope">
          {{ timestampToTime(scope.row.TimeStamp) }}
        </template>
      </el-table-column>
      <el-table-column prop="Hash" label="存证码(Hash)" show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="操作" width="160" align="center">
        <template slot-scope="scope">
          <!--  -->
          <el-button  :disabled="scope.row.BillId.length< 19" type="text" @click="handleView(scope.row)"
            >查看回执</el-button
          >
          <el-button
            type="text"
            :disabled="!scope.row.evidenceId"
            @click="handleDownload([scope.row.evidenceId])"
            >下载存证</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="total,prev, pager, next,sizes"
      :total="pagination.total"
      :current-page="pagination.current"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.pageSizeOptions"
      style="text-align: right; margin-top: 8px; padding: 0"
      @current-change="currentChange"
      @size-change="handleSizeChange"
    >
    </el-pagination>
  </div>
</template>

<script>
const downloadFile = (url, downloadName = "") => {
  // console.log(url);
  const link = document.createElement("a");
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      link.href = URL.createObjectURL(blob);
      // console.log(link.href)
      link.download = downloadName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
import radioGroup from "@/components/CompanyRadioGroup.vue";
import axios from "axios";
export default {
  name: "Home",
  components: {
    radioGroup,
    // waybillForm
  },
  data() {
    return {
      activeName: "first",
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      tableData: [],
      queryParam: { SupplieId: "全部", Status: "0" },
      keyword: "",
      loading: false,
      dialogVisible: false,
      templateId: "",
      templateList: [],
      tabList: [{ label: "待签收", name: "first" }],
      ip: "",
      selectRows: [],
      row: {},
      addloading: false,
    };
  },
  mounted() {},
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  methods: {
    // 下载
    handleDownload(arr) {
     if(arr.length<1){
      this.$message.error("请选择至少一条单据！");
      return
     }
      let str = arr.join(",");
      this.addloading = true;
      axios({
        method: "post",
        url: this.$baseUrl + "/Base_Manage/Home/Base64DecodeString",
        data: { evidenceId: str },
        responseType: "blob",
      }).then((res) => {
        if (res.status == 200) {
          const url = URL.createObjectURL(res.data);
          const link = document.createElement("a");
          document.body.appendChild(link);
          link.download = "文件" + ".zip";
          link.href = url;
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }
        this.addloading = false;
      });
    },
    timestampToTime(timestamp) {
      // 时间戳为10位需*1000，时间戳为13位不需乘1000
      var date = new Date(timestamp - 28800000);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    // 查看
    handleView(row) {
      console.log(row.Id);
      let url = `${location.href.split("#")[0]}#/SendSMS?prop=${
        row.BillId
      }&TimeStamp=${row.TimeStamp}`;
      window.open(url, "_blank");
    },
    // 多选
    handleSelectionChange(rows) {
      this.selectRows = rows.map((item) => item.evidenceId);
    },
    radioComplete(e) {
      console.log(e);
      if (e) {
        this.queryParam.SupplieId = e;
        this.getDataList();
      }
    },
    // 打开单据页面
    openNewPage(row) {
      // let isClient = sessionStorage.getItem("client");
      let url = `${location.href.split("#")[0]}#/SendSMS?prop=${row.Id}`;
      //  if (isClient) {
      //   url = `${location.href.split("#")[0]}#/SendSMSCopy?prop=${row.Id}`;
      // }
      window.open(url, "_blank");
    },
    isRender(row) {
      if (!row.evidenceId) {
        return false;
      } else return true;
    },
    // 公司切换
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.SupplieId = e;
      this.getDataList();
    },
    // 页签切换
    handleClick(tab, event) {
      this.pagination = {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      };
      this.queryParam.Status = this.activeName + "";
      if (this.activeName == "first") this.queryParam.Status = "";
      this.getDataList();
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      let usr = JSON.parse(sessionStorage.getItem("userInfo"));
      console.log(usr.UserPhone);
      this.$http
        .post("/BO/BillList/FindListByPage", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: {
            Keyword: this.queryParam.SupplieId,
          },
        })
        .then((resJson) => {
          this.loading = false;
          //   this.tableData = resJson.Data;
          //   console.log(resJson);
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.searchNav .el-button {
  background: #d50700;
  border-color: #d50700;
  color: #fff;
}
/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #fff;
    background-color: #d50700;
    border-color: #d50700;
    box-shadow: -1px 0 0 0 #d50700;
}
/deep/.el-radio-button__inner:hover {
    color: #d50700;
}
/deep/ .el-tabs__nav {
  width: 100%;
  background: #fff;
}

/deep/ .el-tab-pane {
  width: 100%;
  background: #fff;
}

/deep/ .el-tabs__item {
  width: 16.6%;
  text-align: center;
  font-weight: bold;
  padding: 0;
}

/deep/ .el-tabs__item:hover {
  background: #ecf5ff;
}
</style>
